import React from 'react';
import './Less/BikePropertiesTabSectionLarge.style.css';
import { GetLocaleText } from '../../../I18n';


const BikeLegalInfo: React.FC = (
	props
): React.ReactElement => {
	return (
		<div className='bike-legal-info'>
			<p>{GetLocaleText('bikes_page_note')}</p>
		</div>
	);
};

export default BikeLegalInfo;
